<script>
import { initFlowbite } from 'flowbite'

export default defineComponent({
  setup() {
    const config = useRuntimeConfig()
    const { loggedIn } = storeToRefs(useAuth())
    const layout = ref('')
    const route = useRoute()
    const metaPage = computed(() => route.meta)

    const idleTimeout = ref(config.public.idleTime)// 15 menit
    const idleTimer = ref(null)
    const addEvent = ref(false)

    const logout = async () => {
      useAuth().logout()
    }
    const resetIdleTimer = () => {
      clearTimeout(idleTimer.value)
      idleTimer.value = setTimeout(logout, idleTimeout.value)
    }

    const setLayout = () => {
      if (metaPage.value.layout !== undefined)
        layout.value = metaPage.value.layout
      else
        layout.value = 'template'
    }

    onMounted(() => {
      initFlowbite()
      setLayout()
      const allInputs = document.querySelectorAll('input')
      allInputs.forEach((input) => {
        input.setAttribute('autocomplete', 'off')
      })
    })

    watchEffect(() => {
      setLayout()
      if (typeof window !== 'undefined') {
        if (loggedIn.value === true && !addEvent.value) {
          resetIdleTimer()
          window.addEventListener('mousemove', resetIdleTimer)
          window.addEventListener('keydown', resetIdleTimer)
          addEvent.value = true
        }
        else if (loggedIn.value === false && addEvent.value) {
          window.removeEventListener('mousemove', resetIdleTimer)
          window.removeEventListener('keydown', resetIdleTimer)
          addEvent.value = false
        }
      }
    })

    return {
      layout,
      idleTimeout,
      idleTimer,
      resetIdleTimer,
      logout,
    }
  },
})
</script>

<template>
  <div>
    <Head>
      <Title>Sally CMS</Title>
    </Head>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
